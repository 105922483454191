import { Button, Descriptions, Form, InputNumber, Layout, Radio, Spin, Table } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import SubHeader from "../../components/common/antd/SubHeader";
import { URL_QC } from "../../constance/urls";
import { GET, POST } from "../../utils/HttpClient";
import { defaultRawLogWoodQC, getSortTypeRender, QC_WASTE_WOOD_CHOICES } from "../../constance/dataTypes";
import * as _ from "lodash";
import dayjs from "dayjs";
import { DATE_HUMAN_FORMAT } from "../../constance/format";
import ErrorMessage from "../../components/common/antd/ErrorMessage";
import { PATH } from "../../routes/Routes";
import { QC_MENUS } from "./QCMainView";
import QCLogWoodWasteWoodTableForm from "../../components/qc-log-wood/QCLogWoodWasteWoodTableForm";
import QCLogWoodEvalSizeTableForm from "../../components/qc-log-wood/QCLogWoodEvalSizeTableForm";


const TABS = {
  EVAL_SIZE: 'eval_size',
  QC_WASTE_WOOD: 'qc_waste_wood'
}

export default function QCLogWoodDetailView() {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();

  const [data, setData] = React.useState(defaultRawLogWoodQC);
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [activeTab, setActiveForm] = React.useState(TABS.EVAL_SIZE);

  const onBack = () => {
    history.push(PATH.QC_MAIN + QC_MENUS.LOG_WOOD)
  }

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_QC.LOG_WOOD_QC}${id}/`);
      setData(response.data);
      form.setFieldsValue(_.get(response, 'data.detail', {}))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await POST(URL_QC.QC_LOG_WOOD_QC_TRANSACTION, values);
      onBack();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, [id])

  return (
    <Layout>
      <SubHeader title={t('qc_menus.log_wood')} onBack={onBack}/>
      <Layout.Content style={{ padding: '0 1rem', marginBottom: '1rem' }}>
        <ErrorMessage message={errorMessages}/>
        <Spin spinning={loading} indicator={<LoadingOutlined/>} size='large' tip={t('loading')}>
          <Descriptions style={{ marginTop: '1rem' }} title={t('log_wood_qcs.title')} column={1} bordered items={[
            {
              key: 'logWoodEvalSize',
              label: t('log_wood_qcs.log_wood_eval_size'),
              children: _.get(data, 'logWoodEvalSize.name', '-')
            },
            {
              key: 'logWoodEvalLength',
              label: t('log_wood_qcs.log_wood_eval_length'),
              children: _.get(data, 'logWoodEvalLength.name', '-')
            },
            {
              key: 'sawTime',
              label: t('log_wood_qcs.saw_time'),
              children: _.get(data, 'sawTime.timeName', _.get(data, 'logWoodWage.sawTime.timeName', '-'))
            },
            {
              key: 'date',
              label: t('date'),
              children: dayjs(_.get(data, 'qcDateTime', '')).format(DATE_HUMAN_FORMAT)
            },
            {
              key: 'sort_type',
              label: t('log_wood_qcs.sort_type'),
              children: getSortTypeRender(_.get(data, 'sort_type', 0)) ? t(getSortTypeRender(_.get(data, 'sort_type', 0))) : '-'
            },
            {
              key: 'date',
              label: t('log_wood_qcs.employee'),
              children: _.get(data, 'logWoodWage.wageDetailList.0.employee.name', '')
            },
          ]}/>
        </Spin>

        <Form form={form} style={{ marginTop: '2rem' }} onFinish={handleSubmit}>
          <Form.Item name='log_wood_qc' hidden initialValue={id}/>
          <Radio.Group
            block
            options={[
              {
                value: TABS.EVAL_SIZE,
                label: <span><CheckCircleOutlined/> {t('log_wood_qcs.log_wood_eval_size')}</span>,
              },
              {
                value: TABS.QC_WASTE_WOOD,
                label: <span><ExclamationCircleOutlined /> {t('log_wood_qcs.qc_waste_woods')}</span>,
              },
            ]}
            size='large'
            value={activeTab}
            optionType='button'
            onChange={(e) => setActiveForm(e.target.value)}/>
  
          <QCLogWoodEvalSizeTableForm
            form={form}
            style={{ display: activeTab == TABS.EVAL_SIZE ? 'block' : 'none', marginTop: '1rem' }}/>

          <QCLogWoodWasteWoodTableForm
            form={form}
            style={{ display: activeTab == TABS.QC_WASTE_WOOD ? 'block' : 'none', marginTop: '1rem' }}/>

          <Button type='primary' size='large' style={{ marginTop: '1rem' }} htmlType='submit' loading={loading}>
            <SaveOutlined/>
            {t('save')}
          </Button>
        </Form>
      </Layout.Content>
    </Layout>
  )
}
