import { Flex, Form, Input, Layout, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import MainHeader from "../../components/common/MainHeader";
import Container from "../../components/common/antd/Container";
import { URL_REPORT } from "../../constance/urls";
import * as _ from "lodash";
import ExportReportButton from "../../components/common/antd/ExportReportButton";
import CustomDatePicker from "../../components/common/antd/CustomDatePicker";


export default function QCPostBakingWoodWoodSummaryReportView () {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const date = Form.useWatch('date', form)
  const oven = Form.useWatch('oven', form)

  return (
    <Layout>
      <Layout.Header>
        <MainHeader/>
      </Layout.Header>
      <Layout.Content>
        <Container style={{ padding: "0 1rem", marginTop: '3rem' }}>
          <Typography.Title level={3}>{t('menus.report_menus.qcs.post_baking_wood_summary')}</Typography.Title>
          <Form form={form} layout='horizontal' style={{ marginTop: '3rem' }} labelCol={{ span: 3, offset: 3 }}>
            <Form.Item name='date' label={t('date')} rules={[{ required: true }]}>
              <CustomDatePicker range/>
            </Form.Item>
            <Form.Item name='oven' label={t('post_baking_waste_wood_count.oven')}>
              <Input range/>
            </Form.Item>
          </Form>
          <Flex gap='middle' justify='center' style={{ marginTop: '2rem' }}>
            <ExportReportButton
              url={URL_REPORT.QC_POST_BAKING_WOOD_TRANSACTION_SUMMARY_REPORT}
              params={{
                date_after: _.get(date, 0, null),
                date_before: _.get(date, 1, null),
                oven,
              }}
              method='post'
              validate={form.validateFields}/>
          </Flex>
        </Container>
      </Layout.Content>
    </Layout>
  )
}