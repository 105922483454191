import { Form, Layout, Space, theme, Typography } from "antd"
import React from "react"
import MainHeader from "../../components/common/MainHeader"
import { useTranslation } from "react-i18next"
import ColorButton from "../../components/common/ColorButton";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { URL_QC, URL_REPORT } from "../../constance/urls";
import TSTable from "../../components/common/TSTable";
import Container from "../../components/common/antd/Container";
import * as _ from "lodash";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { PATH } from "../../routes/Routes";
import { HUMAN_DATETIME_FORMAT } from "../../constance/strings";
import ExportReportButton from "../../components/common/antd/ExportReportButton";
import CustomDatePicker from "../../components/common/antd/CustomDatePicker";
import { formatComma } from "../../utils/Utils";


export default function QCWetWoodReportListView () {

  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { token: { colorInfo } } = theme.useToken();
  const tableRef = React.useRef();
  const [params, setParams] = React.useState({});

  const columns = React.useMemo(() => [
    {
      title: t('log_wood_qcs.checks.date'),
      dataIndex: 'created',
      render: (value) => dayjs(value).format(HUMAN_DATETIME_FORMAT)
    },
    {
      title: t('log_wood_qcs.checks.employee'),
      dataIndex: 'created_by_code',
      searcher: true,
    },
    {
      title: t('log_wood_qcs.checks.employee'),
      dataIndex: 'created_by_name',
      searcher: true,
    },
    {
      title: t('wood_datas.employee'),
      dataIndex: 'employee_code',
      searcher: true,
    },
    {
      title: t('wood_datas.employee'),
      dataIndex: 'employee_name',
      searcher: true,
    },
    {
      title: t('barcodes.barcode_id'),
      dataIndex: 'barcode',
      searcher: true,
    },
    {
      title: t('status'),
      dataIndex: 'is_qc_passed',
      render: (value) => value ? t('qc_sorts.pass') : t('qc_sorts.fail'),
      searcher: true,
      searcherOptions: [
        { key: 'passed', value: true, label: t('qc_sorts.pass') },
        { key: 'failed', value: false, label: t('qc_sorts.fail') },
      ]
    },
    {
      title: t('qc_sorts.reason'),
      dataIndex: 'details',
      render: (value) => (
        <div>
          {value.map(el => 
            <div>{_.get(el, 'setting_name', '')} [{formatComma(_.get(el, 'percent'))}]</div>
          )}
        </div>
      )
    },
    {
      title: ' ',
      dataIndex: 'id',
      render: (value) => (
        <Space>
          <ColorButton
            override={colorInfo}
            color='primary'
            variant='outlined'
            icon={<EditOutlined/>}
            onClick={() => history.push(`${PATH.QC_WET_WOOD_REPORT}${value}/`)}
          >
            {t('edit')}
          </ColorButton>
        </Space>
      )
    },
  ], [])

  const onSearch = async ({ date, ...value }) => {
    setParams({ ...value, date_after: _.get(date, 0, null), date_before: _.get(date, 1, null) })
  }

  return (
    <Layout>
      <Layout.Header>
        <MainHeader/>
      </Layout.Header>
      <Layout.Content>
        <Container style={{ padding: "0 1rem", marginTop: '3rem' }}>
          <Typography.Title level={3}>{t('menus.report_menus.qcs.wet_wood')}</Typography.Title>
          <Form form={form} onFinish={onSearch} layout='inline' style={{ justifyContent: 'center' }}>
            <Form.Item name='date' label={t('date')}>
              <CustomDatePicker style={{ width: '100%' }} range/>
            </Form.Item>
            <ColorButton type='primary' override={colorInfo} htmlType='submit' icon={<SearchOutlined/>}>{t('search')}</ColorButton>
          </Form>
          <ExportReportButton
            style={{ marginTop: '1rem' }}
            justify='end'
            url={URL_REPORT.QC_WET_WOOD_TRANSACTION_REPORT}
            method='get'
            exportTypes={['excel']}
            typeAsParams
            params={params}/>
          <TSTable
            ref={tableRef}
            url={URL_QC.QC_WET_WOOD_TRANSACTION}
            columns={columns}
            style={{ marginTop: '1rem' }}
            params={params} />
        </Container>
      </Layout.Content>
    </Layout>
  )
}