import React from "react";
import { Form, Select } from "antd";
import SearchSelect from "../common/antd/SearchSelect";
import { EMPLOYEE_ROLES, QC_RAW_WOOD_TYPES, WOOD_GRADE_CHOICES } from "../../constance/dataTypes";
import { URL_EMPLOYEE, URL_SCALE_LARGE } from "../../constance/urls";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";
import CustomDatePicker from "../common/antd/CustomDatePicker";


export default function DefectTypeDataForm (props) {
  const {
    type,
    form,
  } = { ...defaultProps, ...props };

  const { t } = useTranslation();
  const produceDate = Form.useWatch('produce_date', form)
  const [employeeParams, setEmployeeParams] = React.useState({});
  const selectRef = React.useRef();

  const getEmployeeLabel = () => {
    switch (type) {
      case QC_RAW_WOOD_TYPES.SAW_DEFECT: return t('qc_raw_woods.on_table_employee')
      case QC_RAW_WOOD_TYPES.DRY_DEFECT: return t('qc_raw_woods.sort_employee')
      default: return t('qc_raw_woods.employee')
    }
  }

  React.useEffect(() => {
    switch (type) {
      case QC_RAW_WOOD_TYPES.SAW_DEFECT: 
        setEmployeeParams({ on_table_produce_date: produceDate })
        return
      case QC_RAW_WOOD_TYPES.DRY_DEFECT:
        setEmployeeParams({ role: EMPLOYEE_ROLES.DRY_WOOD_SORT })
        return
    }
  }, [type])

  React.useEffect(() => {
    if (type == QC_RAW_WOOD_TYPES.SAW_DEFECT) {
      setEmployeeParams({ on_table_produce_date: produceDate })
    }
  }, [produceDate])

  React.useEffect(() => {
    if (selectRef.current) {
      selectRef.current.fetch()
    }
  }, [employeeParams])

  return (
    <div>
      <Form.Item
        name='produce_date' label={t('qc_raw_woods.produce_date')} rules={[{ required: true }]}>
        <CustomDatePicker style={{ width: '100%' }}/>
      </Form.Item>

      <Form.Item name='employee' label={getEmployeeLabel()} rules={[{ required: true }]}>
        <SearchSelect
          ref={selectRef}
          manual={true}
          url={URL_EMPLOYEE.EMPLOYEE}
          placeholder={`${t('select')} ${t('qc_raw_woods.employee')}`}
          optionValue='code'
          labelRender={(data) => `[${data.code}] ${data.name}`}
          params={employeeParams}/>
      </Form.Item>

      { type == QC_RAW_WOOD_TYPES.BUY_DEFECT && (
        <Form.Item name='truck_company' label={t('truck_transactions.company')} rules={[{ required: true }]}>
          <SearchSelect
            url={URL_SCALE_LARGE.TRUCK_COMPANY}
            pagination={false}
            placeholder={`${t('select')} ${t('truck_transactions.company')}`}
            optionValue='code'
            labelRender={(data) => `[${data.code}] ${data.name}`}/>
        </Form.Item>
      )}

      <Form.Item name='grade' label={t('wood_imports.grade')} rules={[{ required: true }]}>
        <Select
          options={WOOD_GRADE_CHOICES}
          labelRender='text'
          placeholder={`${t('select')} ${t('wood_imports.grade')}`}/>
      </Form.Item>
    </div>
  )
}

const defaultProps = {
  type: '',
  form: null,
}

DefectTypeDataForm.propTypes = {
  type: propTypes.string,
  form: propTypes.any,
}