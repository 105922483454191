import { Collapse, Form, Input, InputNumber, message, Spin, Table, Typography } from "antd";
import React from "react";
import { URL_QC } from "../../constance/urls";
import { GET } from "../../utils/HttpClient";
import { useTranslation } from "react-i18next";
import { WOOD_COLORS } from "../../constance/dataTypes";
import * as _ from "lodash";


export default function DetailForm (props) {
  const {
    form,
  } = { ...defaultProps, ...props };

  const { t } = useTranslation();
  const date = Form.useWatch('leave_oven_date', form)
  const ovens = Form.useWatch('oven', form)
  const [loading, setLoading] = React.useState(false);
  const [palletData, setPalletData] = React.useState({ AB: 0, C: 0, P: 0, PP: 0, NAC: 0, ABC: 0 })
  const palletTotal = Object.values(palletData).reduce((prev, curr) => prev + Number(curr) | 0, Number(0))
  const gradePalletMaps = Object.entries(palletData).filter(([__, amount]) => amount > 0)
  const details = Form.useWatch('details', form)

  const columns = [
    {
      title: t('wood_datas.color'),
      render: ({ name }) => (
        <span>
          <Form.Item name={[name, 'color']} style={{ margin: 0 }}>
            <Input variant='borderless' readOnly/>
          </Form.Item>
          <Form.Item name={[name, 'grade']} hidden/>
        </span>
      )
    },
    {
      title: t('qc_raw_woods.wood_groups.thin'),
      render: ({ name }) => (
        <Form.Item name={[name, 'thin']} style={{ margin: 0 }} normalize={value => value ? value : 0}>
          <InputNumber variant='borderless' placeholder='0' style={{ width: '100%' }} />
        </Form.Item>
      )
    },
    {
      title: t('qc_raw_woods.wood_groups.medium'),
      render: ({ name }) => (
        <Form.Item name={[name, 'medium']} style={{ margin: 0 }} normalize={value => value ? value : 0}>
          <InputNumber variant='borderless' placeholder='0' style={{ width: '100%' }} />
        </Form.Item>
      )
    },
    {
      title: t('qc_raw_woods.wood_groups.thick'),
      render: ({ name }) => (
        <Form.Item name={[name, 'thick']} style={{ margin: 0 }} normalize={value => value ? value : 0}>
          <InputNumber variant='borderless' placeholder='0' style={{ width: '100%' }} />
        </Form.Item>
      )
    },
    {
      title: t('qc_raw_woods.wood_groups.edge'),
      render: ({ name }) => (
        <Form.Item name={[name, 'edge']} style={{ margin: 0 }} normalize={value => value ? value : 0}>
          <InputNumber variant='borderless' placeholder='0' style={{ width: '100%' }} />
        </Form.Item>
      )
    },
  ]

  const fetchPalletSummary = async () => {
    setLoading(true);
    try {
      const response = await GET(URL_QC.POST_BAKING_PALLET_COUNT_SUMMARY, { date, ovens })
      setPalletData(response.data)
    } catch (error) {
      message.error({ content: error.errorMessages })
    } finally {
      setLoading(false);
    }
  }

  const setFormDetails = () => {
    const originalDetails = form.getFieldValue('details')
    const initialValue = gradePalletMaps.map(([grade, __]) => [
      ...Object.values(WOOD_COLORS).map(color => {
        const found = originalDetails ? originalDetails.find(detail => detail.grade == grade && detail.color == color) : null
        return {
          grade, color,
          thin: _.get(found, 'thin', 0),
          medium: _.get(found, 'medium', 0),
          thick: _.get(found, 'thick', 0),
          edge: _.get(found, 'edge', 0)
        }
      })
    ]).flat()
    form.setFieldValue('details', initialValue)
  }

  React.useEffect(() => {
    if (date && ovens) {
      fetchPalletSummary()
    }
  }, [date, ovens])

  React.useEffect(() => {
    if (palletTotal > 0) {
      setFormDetails()
    }
  }, [palletData])

  return (
    <div>
      <Spin spinning={loading}>
        <Form.List name='details'>
          {(fields) => (
            <div style={{ textAlign: 'left' }}>
              <Collapse
                ghost
                activeKey={gradePalletMaps.map(([grade, amount]) => grade)}
                items={gradePalletMaps.map(([grade, amount]) => ({
                  key: grade,
                  label: <Typography.Title level={4}>{grade} {t('datas.amount')} : {amount} {t('units.pallet')}</Typography.Title>,
                  children: (
                    <div>
                      <Table
                        columns={columns}
                        dataSource={fields.filter(field => form.getFieldValue(['details', field.name, 'grade']) == grade)}
                        pagination={false}
                        summary={() => (
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>{t('datas.sum')}</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              <InputNumber
                                style={{ width: '100%' }}
                                variant='borderless'
                                placeholder='0'
                                readOnly
                                value={details ? details.filter(detail => detail.grade === grade).reduce((prev, detail) => prev + Number(_.get(detail, 'thin', 0)) || 0, Number(0)) : 0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                              <InputNumber
                                style={{ width: '100%' }}
                                variant='borderless'
                                placeholder='0'
                                readOnly
                                value={details ? details.filter(detail => detail.grade === grade).reduce((prev, detail) => prev + Number(_.get(detail, 'medium', 0)) || 0, Number(0)) : 0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                              <InputNumber
                                style={{ width: '100%' }}
                                variant='borderless'
                                placeholder='0'
                                readOnly
                                value={details ? details.filter(detail => detail.grade === grade).reduce((prev, detail) => prev + Number(_.get(detail, 'thick', 0)) || 0, Number(0)) : 0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              <InputNumber
                                style={{ width: '100%' }}
                                variant='borderless'
                                placeholder='0'
                                readOnly
                                value={details ? details.filter(detail => detail.grade === grade).reduce((prev, detail) => prev + Number(_.get(detail, 'edge', 0)) || 0, Number(0)) : 0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}/>
                    </div>
                  )
                }))}/>
            </div>
          )}
        </Form.List>
      </Spin>
      <Typography.Title level={4} style={{ textAlign: 'left' }} >{t('datas.sum')}</Typography.Title>
      <Table 
        style={{ marginBottom: '1rem' }}
        columns={[
          {
            title: t('qc_raw_woods.wood_groups.thin'),
            dataIndex: 'thin',
          },
          {
            title: t('qc_raw_woods.wood_groups.medium'),
            dataIndex: 'medium',
          },
          {
            title: t('qc_raw_woods.wood_groups.thick'),
            dataIndex: 'thick',
          },
          {
            title: t('qc_raw_woods.wood_groups.edge'),
            dataIndex: 'edge',
          },
          {
            title: t('datas.total'),
            dataIndex: 'total',
          },
        ]}
        dataSource={[{
          thin: details ? details.reduce((prev, detail) => prev + Number(detail.thin) || Number(0), Number(0)) : 0,
          medium: details ? details.reduce((prev, detail) => prev + Number(detail.medium) || Number(0), Number(0)) : 0,
          thick: details ? details.reduce((prev, detail) => prev + Number(detail.thick) || Number(0), Number(0)) : 0,
          edge: details ? details.reduce((prev, detail) => prev + Number(detail.edge) || Number(0), Number(0)) : 0,
          total: details ? details.reduce((prev, detail) => {
            const total = (
              (Number(detail.thin) || Number(0)) +
              (Number(detail.medium) || Number(0)) +
              (Number(detail.thick) || Number(0)) +
              (Number(detail.edge) || Number(0))
            )
            return prev + total
          }, Number(0)) : 0,
        }]}
        pagination={false}/>
    </div>
  )
}


const defaultProps = {

}