import { CheckOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Typography } from "antd";
import propTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import NormalTypeDataForm from "./NormalTypeDataForm";


export default function InputNormalTypeData (props=defaultProps) {
  const {
    onSubmit,
    onBack,
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <div>
      <Typography.Title level={5} type='secondary'>{t('qc_raw_woods.normal')}</Typography.Title>
      <Form form={form} onFinish={onSubmit} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} style={{ textAlign: 'left' }}>
        <NormalTypeDataForm form={form} />
        <Flex justify='center' gap='middle'>
          <Button type='primary' icon={<CheckOutlined/>} htmlType="submit">{t('confirm')}</Button>
          <Button onClick={onBack}>{t('back')}</Button>
        </Flex>
      </Form>
    </div>
  )
}

const defaultProps = {
  onSubmit: () => null,
  onBack: () => null,
}

InputNormalTypeData.propTypes = {
  onSubmit: propTypes.func,
  onBack: propTypes.func,
}