import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// import { Button, Header, Icon, Input, Modal } from "semantic-ui-react";
import { useDebounce } from "use-debounce";
import ErrorMessage from "../common/antd/ErrorMessage";
import { POST } from "../../utils/HttpClient";
import * as _ from "lodash";
import { QrReader } from 'react-qr-reader';
import { Input, Modal, theme, Typography } from "antd";
import ColorButton from "../common/ColorButton";
import { QrcodeOutlined } from "@ant-design/icons";
import { barcodeErrorMessages } from "../../constance/dataTypes";


const DEFAULT_ON_SUBMIT_LENGTH = 14;

const BarcodeInput = React.forwardRef((props, ref) => {
  const {
    submitUrl,
    onNext,
  } = props;

  const { t } = useTranslation(); 
  const inputRef = React.useRef();
  const [barcodeInput, setBarcodeInput] = React.useState('')
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [barcodeSearch] = useDebounce(barcodeInput, 500);
  const [scanModal, setScanModal] = React.useState(false);
  const { token: { colorInfo } } = theme.useToken();

  const handleSubmit = async (barcode) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await POST(submitUrl, { barcode });
      onNext(_.get(response, 'data.id', ''));
    } catch (error) {
      if (barcodeErrorMessages.includes(error.errorMessages)) {
        setErrorMessages(t('error_messages.barcodes.' + error.errorMessages))
      } else {
        setErrorMessages(error.errorMessages);
      }
    } finally {
      setLoading(false);
    }
  }

  const handleOpenQRCode = () => {
    if (window.JSInterface) {
      // if open in ww factory then use interface
      window.JSInterface.scanBarcode();
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      // if https then go normal library scan qr
      setScanModal(true);
    }
  }

  const onBarcodeScanned = (values) => {
    // for handling android function called
    setBarcodeInput(values)
  }

  React.useEffect(() => {
    if (barcodeSearch.length == DEFAULT_ON_SUBMIT_LENGTH) {
      handleSubmit(barcodeSearch);
    }
  }, [barcodeSearch])

  React.useEffect(() => {
    window.onBarcodeScanned = (values) => {
      onBarcodeScanned(values)
    }

    return () => {
      window.onBarcodeScanned = () => null
    }
  }, [])

  return (
    <div style={{ marginTop: '7rem' }}>
      <ErrorMessage message={errorMessages}/>
      <Typography.Title level={4}>{t('barcodes.barcode_input')}</Typography.Title>
      <Input
        style={{ maxWidth: "300px" }}
        size='large'
        ref={inputRef}
        loading={loading}
        disabled={loading}
        value={barcodeInput}
        onChange={(e) => setBarcodeInput(e.target.value)} />

      <div style={{ marginTop: '3rem' }}>
        <ColorButton
          type='primary'
          override={colorInfo}
          size='large'
          loading={loading}
          icon={<QrcodeOutlined/>}
          onClick={handleOpenQRCode}
        >
          {t('scan')}
        </ColorButton>
      </div>

      <Modal
        open={scanModal}
        title={<span><QrcodeOutlined/> {t('barcodes.barcode_input')}</span>}
        onCancel={() => setScanModal(false)}
        footer={[]}
        closeIcon
      >
        <QrReader
          style={{ width: '100%' }}
          constraints={{ facingMode: "environment" }}
          onResult={(result, e) => {
            if (result) {
              setBarcodeInput(_.get(result, 'text', ''));
              setScanModal(false);
            }
          }}/>
      </Modal>
    </div>
  )
})

export default BarcodeInput;

BarcodeInput.propTypes = {
  submitUrl: PropTypes.string,
  onNext: PropTypes.func,
}

BarcodeInput.defaultProps = {
  submitUrl: '',
  onNext: () => null,
}