export const URL_AUTH = {
	API_TOKEN_AUTH: "/api/users/api-token-auth/",
	LIFF_API_TOKEN_AUTH: "/api/users/liff-token-auth/",
	REGISTER_NOTIFICATION_TOKEN: "/api/users/register-notification-token/",
	CHANGE_PASSWORD: "/api/users/change-password/",
	IS_ADMIN: "/api/users/is-admin/",
};

export const URL_SCALE_LARGE = {
	TRUCK_TRANSACTION: "/api/scale_large/truck-transaction/",
	TRANSACTION_TYPE: "/api/scale_large/transaction-type/",
	TRUCK: "/api/scale_large/truck/",
	TRUCK_TYPE: "/api/scale_large/truck-type/",
	TRUCK_COMPANY: "/api/scale_large/truck-company/",
	TRUCK_PRODUCT: "/api/scale_large/truck-product/",
	TRUCK_TRANSACTION_SYNC: "/api/scale_large/truck-transaction-sync/",
}

export const URL_LOG_WOOD = {
	LOG_WOOD_EVALUATION_DATA: "/api/log_wood/log-wood-evaluation-data/",
	COURTYARD: "/api/log_wood/courtyard/",
	LOG_WOOD_TYPE: "/api/log_wood/log-wood-type/",
	LOG_WOOD_SPECIES: "/api/log_wood/log-wood-species/",
	LOG_WOOD_SPECIES_GRADE: "/api/log_wood/log-wood-species-grade/",
	LOG_WOOD_EVALUATE_LENGTH: "/api/log_wood/log-wood-eval-length/",
	CUT_WEIGHT: "/api/log_wood/cut-weight/",
	WOOD_PLOT: "/api/log_wood/wood-plot/",
	LOG_WOOD_EVALUATE_SIZE: "/api/log_wood/log-wood-eval-size/",
	CUT_WEIGHT_EVALUATE: "/api/log_wood/cut-weight-evaluate/",
	LOG_WOOD_EVALUATE_DIAMETER: "/api/log_wood/log-wood-eval-diameter/",
	LOG_WOOD_SPECIES_EVALUATE: "/api/log_wood/log-wood-species-evaluate/",
	LOG_WOOD_EVALUATION_DATA_SYNC: "/api/log_wood/log-wood-evaluation-data-sync/",
	// report	
	REPORT_LOG_WOOD_LENGTH: "/api/log_wood/report-log-wood-length/",
	REPORT_LOG_WOOD_LENGTH_EXPORT: "/api/log_wood/report-log-wood-length-export/",
	REPORT_LOG_WOOD_LENGTH_CUSTOMER: "/api/log_wood/report-log-wood-length-customer/",
	REPORT_LOG_WOOD_LENGTH_CUSTOMER_EXPORT: "/api/log_wood/report-log-wood-length-customer-export/",
	REPORT_LOG_WOOD_SPECIES: "/api/log_wood/report-log-wood-species/",
	REPORT_LOG_WOOD_SPECIES_EXPORT: "/api/log_wood/report-log-wood-species-export/",
	REPORT_LOG_WOOD_SPECIES_CUSTOMER: "/api/log_wood/report-log-wood-species-customer/",
	REPORT_LOG_WOOD_SPECIES_CUSTOMER_EXPORT: "/api/log_wood/report-log-wood-species-customer-export/",
	REPORT_CUT_WEIGHT: "/api/log_wood/report-cut-weight/",
	REPORT_CUT_WEIGHT_EXPORT: "/api/log_wood/report-cut-weight-export/",
}

export const URL_WORKFLOW = {
	LOG_WOOD_PO_WORKFLOW: "/api/workflow/log-wood-po/",
	LOG_WOOD_PO_WORKFLOW_HISTORY: "/api/workflow/log-wood-po-history/",

	WOOD_IMPORT_WORKFLOW: "/api/workflow/wood-import/",
	WOOD_IMPORT_WORKFLOW_HISTORY: "/api/workflow/wood-import-history/",
}

export const URL_BARCODE = {
	BARCODE: "/api/barcode/barcode/",
	BARCODE_PARSING: "/api/barcode/barcode-parsing/",
	WOOD_EXPORT_TRANSACTION: "/api/barcode/wood-export-transaction/",
	WOOD_EXPORT_TRANSACTION_EXPORT: "/api/barcode/wood-export-transaction-export/",
	WOOD_IMPORT_TRANSACTION: "/api/barcode/wood-import-transaction/",
	WOOD_SIZE_BUY_PRICE_MAP: "/api/barcode/wood-size-buy-price-map/",
	WOOD_SIZE_BUY_PRICE_MAP_IMPORT: "/api/barcode/wood-size-buy-price-map-import/",
	WOOD_SIZE_BUY_PRICE_MAP_EXPORT: "/api/barcode/wood-size-buy-price-map-export/",
	SAW_WOOD_TYPE: "/api/barcode/saw-wood-type/",
	WOOD_EXPORT: "/api/barcode/wood-export/",
	SAW_TIME: "/api/barcode/saw-time/",
	WET_WOOD_DATA: "/api/barcode/wet-wood-data/",
	DRY_WOOD_DATA: "/api/barcode/dry-wood-data/",
	// report
	REPORT_WOOD_IMPORT_EXPORT: "/api/barcode/wood-import-report-export/",
	REPORT_WOOD_IMPORT_CUSTOMER: "/api/barcode/report-wood-import-customer/",
	REPORT_WOOD_IMPORT_CUSTOMER_EXPORT: "/api/barcode/report-wood-import-customer-export/",
	REPORT_WOOD_IMPORT_CUSTOMER_DATE: "/api/barcode/report-wood-import-customer-date/",
	REPORT_WOOD_IMPORT_CUSTOMER_DATE_EXPORT: "/api/barcode/report-wood-import-customer-date-export/",
	REPORT_WOOD_IMPORT_VOLUME: "/api/barcode/report-wood-import-volume/",
	REPORT_WOOD_IMPORT_VOLUME_EXPORT: "/api/barcode/report-wood-import-volume-export/",
	REPORT_WOOD_IMPORT_WOOD_CODE: "/api/barcode/report-wood-import-wood-code/",
	REPORT_WOOD_IMPORT_WOOD_CODE_EXPORT: "/api/barcode/report-wood-import-wood-code-export/",
	REPORT_WOOD_IMPORT_WOOD_THICK: "/api/barcode/report-wood-import-wood-thick/",
	REPORT_WOOD_IMPORT_WOOD_THICK_EXPORT: "/api/barcode/report-wood-import-wood-thick-export/",
	REPORT_WOOD_IMPORT_OUT_GRADE: "/api/barcode/report-wood-import-out-grade/",
	REPORT_WOOD_IMPORT_OUT_GRADE_EXPORT: "/api/barcode/report-wood-import-out-grade-export/",
}

export const URL_INTERFACE_AX = {
	INTERFACE_TRANSACTION: "/api/interface_ax/interface-transaction/",
	LOG_WOOD_PO: "/api/interface_ax/log-wood-po/",
	PROCESSED_WOOD_PO: '/api/interface_ax/processed-wood-po/',
	MOVEMENT_ISSUE: '/api/interface_ax/movement-issue/',
	MOVEMENT_RECEIPT: '/api/interface_ax/movement-receipt/',
	BOM_TRANSACTION: '/api/interface_ax/bom-transaction/',
	EXPORT_TRANSACTION: '/api/interface_ax/export-transaction/',
	POST_BAKING_WASTE_WOOD_COUNT: '/api/interface_ax/post-baking-waste-wood-count/',
	WASTE_WOOD_ORDER: "/api/interface_ax/waste-wood-order/",
	WASTE_WOOD_ORDER_GRADING: "/api/interface_ax/waste-wood-order-grading/",
	BOM_WASTE_WOOD: "/api/interface_ax/bom-waste-wood/",
}

export const URL_BOM = {
	BOM_TRANSACTION: "/api/bom/bom-transaction/",
	BOM: "/api/bom/bom/",
	MULTIPLE_UPDATE: "multiple-update/",
	ADD_BARCODE: "/add-barcode/",
}

export const URL_WOOD_EXPORT = {
	EXPORT_TRANSACTION: "/api/wood_export/export-transaction/",
	EXPORT_SALE_LINES: "/export-sale-lines/",
	EXPORT_ITEM: "/api/wood_export/export-item/",
	INTERFACE: "/interface/"
}

export const URL_AX_DB = {
	AX_EXPORT_DOC: "/api/ax_db/ax-export-doc/",
}

export const URL_SYNC_DATA = {
	SYNC_AX_CODE: "/api/sync_data/sync-ax-code/",
	CHECK_SYNC_DATA: "/api/sync_data/check-sync-data/",
	SYNC_EMPLOYEE: "/api/sync_data/sync-employee/",
	SYN_DATA_TRANSACTION: "/api/sync_data/sync-data-transaction/",
}

export const URL_POST_BAKING = {
	CHECK_POST_BAKING_GROUP: "/api/post_baking/check-post-baking-group/",
	POST_BAKING_WASTE_WOOD_COUNT: "/api/post_baking/post-baking-waste-wood-count/",
	POST_BAKING_WASTE_WOOD_COUNT_WOOD_SIZE_AMOUNT_MAP: "/api/post_baking/post-baking-waste-wood-count-wood-size-amount-map/",
	OVEN_SET: "/api/post_baking/oven-set/",
	OVEN_SET_DETAIL: "/api/post_baking/oven-set-detail/",
	POST_BAKING_WASTE_WOOD_INPUT_DONE_CHECK: "/api/post_baking/post-baking-waste-wood-input-done-check/",
	POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION: "/api/post_baking/post-baking-waste-wood-input-transaction/",
	POST_BAKING_WASTE_WOOD_INPUT: "/api/post_baking/post-baking-waste-wood-input/",
}

export const URL_REPORT = {
	POST_BAKING_WASTE_WOOD_COUNT_REPORT: "/api/report/post-baking-waste-wood-count-report/",
	POST_BAKING_WASTE_WOOD_INPUT_REPORT: "/api/report/post-baking-waste-wood-input-report/",
	POST_BAKING_WASTE_WOOD_SUMMARY_REPORT: "/api/report/post-baking-waste-wood-summary-report/",
	POST_BAKING_WASTE_WOOD_INPUT_SUMMARY_REPORT: "/api/report/post-baking-waste-wood-input-summary-report/",
	QC_LOG_WOOD_QC_TRANSACTION_REPORT: "/api/report/qc-log-wood-qc-transaction-report/",
	QC_LOG_WOOD_QC_TRANSACTION_SUMMARY_REPORT: "/api/report/qc-log-wood-qc-transaction-summary-report/",
	QC_RAW_WOOD_TRANSACTION_REPORT: "/api/report/qc-raw-wood-transaction-report/",
	QC_RAW_WOOD_TRANSACTION_SAW_EMPLOYEE_REPORT: "/api/report/qc-raw-wood-transaction-saw-employee-report/",
	QC_RAW_WOOD_TRANSACTION_ON_TABLE_EMPLOYEE_REPORT: "/api/report/qc-raw-wood-transaction-on-table-employee-report/",
	QC_WET_WOOD_TRANSACTION_REPORT: "/api/report/qc-wet-wood-transaction-report/",
	QC_WET_WOOD_TRANSACTION_SUMMARY_REPORT: "/api/report/qc-wet-wood-transaction-summary-report/",
	QC_POST_BAKING_WOOD_TRANSACTION_REPORT: "/api/report/qc-post-baking-wood-transaction-report/",
	QC_POST_BAKING_WOOD_TRANSACTION_SUMMARY_REPORT: "/api/report/qc-post-baking-wood-transaction-summary-report/",
	QC_DRY_WOOD_TRANSACTION_REPORT: "/api/report/qc-dry-wood-transaction-report/",
	QC_DRY_WOOD_TRANSACTION_SUMMARY_REPORT: "/api/report/qc-dry-wood-transaction-summary-report/",
}

export const URL_QC = {
	LOG_WOOD_QC: "/api/qc/log-wood-qc/",
	QC_LOG_WOOD_QC_TRANSACTION: "/api/qc/qc-log-wood-qc-transaction/",
	SAW_WOOD_DETAIL: "/api/qc/saw-wood-detail/",
	QC_RAW_WOOD_SETTING: "/api/qc/qc-raw-wood-setting/",
	QC_RAW_WOOD_TRANSACTION: "/api/qc/qc-raw-wood-transaction/",
	QC_WET_WOOD_SETTING: "/api/qc/qc-wet-wood-setting/",
	QC_WET_WOOD_TRANSACTION: "/api/qc/qc-wet-wood-transaction/",
	QC_WET_WOOD_TRANSACTION_WET_WOOD_DATA: "/api/qc/qc-wet-wood-transaction-wet-wood-data/",
	POST_BAKING_PALLET_COUNT_SUMMARY: "/api/qc/post-baking-pallet-count-summary/",
	QC_POST_BAKING_WOOD_TRANSACTION: "/api/qc/qc-post-baking-wood-transaction/",
	QC_POST_BAKING_WOOD_TRANSACTION_RETRIEVE_BY_DETAIL: "/api/qc/qc-post-baking-wood-transaction/retrieve-by-detail/",
	QC_DRY_WOOD_SETTING: "/api/qc/qc-dry-wood-setting/",
	QC_DRY_WOOD_TRANSACTION: "/api/qc/qc-dry-wood-transaction/",
	QC_DRY_WOOD_TRANSACTION_DRY_WOOD_DATA: "/api/qc/qc-dry-wood-transaction-dry-wood-data/",
}

export const URL_EMPLOYEE = {
	EMPLOYEE: "/api/employee/employee/",
}