import { useFormik } from "formik";
import React from "react";
import { Dimmer, Form, Header, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { formatComma } from "../../utils/stringFormat";
import PropTypes from "prop-types";
import { defaultPostBakingWasteWoodCountWoodSizeAmountMapDetail } from "../../constance/dataTypes";
import * as Yup from "yup";
import CountWasteWoodForm from "./CountWasteWoodForm";
import ErrorMessage from "../common/ErrorMessage";
import { GET, POST, PUT } from "../../utils/HttpClient";
import { URL_POST_BAKING } from "../../constance/urls";
import * as _ from "lodash";
import useCountWasteWoodValidate from "./useCountWasteWoodValidate";


export default function CountWoodAmount (props) {
  const {
    target,
    groupDate,
    ovens,
    getTransaction,
    onBack,
  } = props;

  const { t } = useTranslation();
  const countWasteWoodValdiate = useCountWasteWoodValidate()
  const isCreate = !target ? true : false;

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      grade: Yup.string().required(t('error_messages.validation.required')),
      wood_code_dimension: Yup.string().required(t('error_messages.validation.required'))
        .length(10, t('error_messages.validation.length')),
      amount: Yup.number().required(t('error_messages.validation.required'))
        .moreThan(0, `${t('error_messages.validation.more_than')} 0`).nullable(),
      waste_wood_type: Yup.string().required(t('error_messages.validation.required'))
        .nullable(),
    }),
    initialValues: defaultPostBakingWasteWoodCountWoodSizeAmountMapDetail,
    onSubmit: async (values) => {
      setLoading(true);
      setErrorMessages(null);
      try {
        // validate
        const message = await countWasteWoodValdiate.doValidate({
          date: groupDate, ovens: ovens.join(','), 
          grade: values.grade, wood_code_dimension: values.wood_code_dimension,
          amount: values.amount, exclude_target: isCreate ? null : target,
        })
        if (isCreate) {
          await POST(URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT_WOOD_SIZE_AMOUNT_MAP, { ...values, transaction: await getTransaction() })
        } else {
          await PUT(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT_WOOD_SIZE_AMOUNT_MAP}${target}/`, values)
        }
        setErrorMessages(message)
        onBack(message);
      } catch (error) {
        setErrorMessages(_.get(error, 'errorMessages', error))
      } finally {
        setLoading(false)
      }
    }
  });
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null)

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null)
    try {
      const response = await GET(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT_WOOD_SIZE_AMOUNT_MAP}${target}/`)
      formik.setValues(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (target) {
      fetchData();
    } else {
      formik.resetForm();
    }
  }, [target])

  return (
    <div style={{ marginTop: '1rem' }}>
      <Header as='h4'>
        {t('post_baking_waste_wood_count.group_date')} {moment(groupDate, 'yyyy-MM-DD').format('DD/MM/yyyy')}&emsp;
        { ovens.length != 0 ? 
          (<div>{t('post_baking_waste_wood_count.oven_no')}: {ovens.join(', ')}</div>) : 
          (<div>{t('post_baking_waste_wood_count.all_oven')}</div>)
        }
      </Header>
      <Dimmer inverted active={loading}>
        <Loader inverted content={t('loading')}/>
      </Dimmer>
      <ErrorMessage message={errorMessages} length={50}/>
      <Form>
        <CountWasteWoodForm formik={formik}/>
        <Form.Group inline style={{ justifyContent: 'center' }}>
          <Form.Button
            style={{ marginTop: '2rem' }}
            color='green'
            icon='save'
            content={t('save')}
            loading={loading}
            onClick={formik.handleSubmit}/>
          <Form.Button
            style={{ marginTop: '2rem' }}
            content={t('back')}
            loading={loading}
            onClick={() => onBack()}/>
        </Form.Group>
      </Form>
      <div style={{ position: "fixed", bottom: "1rem", left: "1rem" }}>
        v.1.22.1(fixed)
      </div>
    </div>
  )
}

CountWoodAmount.propTypes = {
  groupDate: PropTypes.string,
  ovens: PropTypes.array,
  getTransactionId: PropTypes.func,
  onBack: PropTypes.func,
}

CountWoodAmount.defaultProps = {
  groupDate: null,
  ovens: [],
  getTransactionId: () => null,
  onBack: () => null,
}