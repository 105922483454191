import { CheckOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Typography } from "antd";
import propTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import DefectTypeDataForm from "./DefectTypeDataForm";


export default function InputDefectTypeData (props=defaultProps) {
  const {
    type,
    onSubmit,
    onBack,
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <div>
      <Typography.Title level={5} type='secondary'>
        {t('qc_raw_woods.defect')}
      </Typography.Title>
      <Typography.Title level={5}>
        {t('qc_raw_woods.type')}: { type && t(`qc_raw_woods.types.${type}`) }
      </Typography.Title>
      <Form form={form} onFinish={onSubmit} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} style={{ textAlign: 'left' }}>
        <DefectTypeDataForm form={form} type={type}/>

        <Flex justify='center' gap='middle'>
          <Button type='primary' icon={<CheckOutlined/>} htmlType='submit'>{t('confirm')}</Button>
          <Button onClick={onBack}>{t('back')}</Button>
        </Flex>
      </Form>
    </div>
  )
}

const defaultProps = {
  type: '',
  onSubmit: () => null,
  onBack: () => null,
}

InputDefectTypeData.propTypes = {
  type: propTypes.string,
  onSubmit: propTypes.func,
  onBack: propTypes.func,
}