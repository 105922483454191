import React from "react";
import propTypes from "prop-types";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { Form, InputNumber, Table } from "antd";
import { EVAL_SIZE_CHOICES } from "../../constance/dataTypes";


export default function QCLogWoodEvalSizeTableForm (props) {
  const {
    form,
    style,
  } = { ...defaultProps, ...props };

  const { t } = useTranslation();
  const targetField = Object.values(EVAL_SIZE_CHOICES).map(choice => "amount_" + choice)
  const watchFields = Form.useWatch([], form)
  const total = targetField.reduce((prev, field) => prev + (Number(_.get(watchFields, field, 0)) || 0), Number(0))

  return (
    <Table
      style={style}
      bordered
      pagination={false}
      columns={[
        {
          dataIndex: 'label',
          title: t('log_wood_qcs.log_wood_eval_size')
        },
        {
          title: t('barcodes.amount'),
          render: ({ key }) => (
            <Form.Item name={`amount_${key}`} style={{ margin: 0 }} normalize={value => value ? value : 0}>
              <InputNumber placeholder='0' variant='borderless' style={{ width: '100%' }}/>
            </Form.Item>
          )
        }
      ]}
      dataSource={Object.values(EVAL_SIZE_CHOICES).map(choice => ({
        key: choice, label: t(`log_wood_eval_sizes.${choice}`),
      }))}
      summary={() => (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>{t('datas.sum')}</Table.Summary.Cell>
          <Table.Summary.Cell index={1}>

            <Form.Item
              name='total_eval_size'
              dependencies={targetField}
              style={{ margin: 0 }}
              rules={[
                () => ({
                  validator() {
                    if (Number(total) <= 0) {
                      return Promise.reject(new Error(t('error_messages.validation.more_than') + ' 0'))
                    }
                    return Promise.resolve()
                  }
                })
              ]}>
                {/* nested div avoid form props take over input number */}
                <div>
                  <InputNumber
                    style={{ width: '100%' }}
                    variant='borderless'
                    placeholder='0'
                    value={total}
                    readOnly
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                </div>
              </Form.Item>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}/>
  )
}


const defaultProps = {
  form: null,
  style: {},
}

QCLogWoodEvalSizeTableForm.propTypes = {
  form: propTypes.any.isRequired,
  style: propTypes.object
} 