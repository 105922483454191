import { POST } from "../../utils/HttpClient";
import { URL_POST_BAKING } from "../../constance/urls";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";


export default function useCountWasteWoodValidate() {
  const { t } = useTranslation();

  const doValidate = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await POST(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT}validate/`, params)
        const isValid = _.get(response, 'data.is_valid', false)
        const remainAmount = _.get(response, 'data.remain_amount', 0)
        const requestAmount = _.get(response, 'data.request_amount', 0)
        let message = ''
        if (remainAmount < requestAmount) {
          message = `${t('post_baking_waste_wood_count.errors.insufficient')} (${remainAmount} ${t('units.wood')}) (${t('over')} ${requestAmount - remainAmount} ${t('units.wood')})`
        }
        if (isValid) {
          resolve(message)
        } else {
          reject(message)
        }
      } catch (error) {
        reject(error.errorMessages)
      }
    })
  }
  
  return { doValidate }
}