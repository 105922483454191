import React from "react";
import { Form, Input, message, Select } from "antd";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";
import { WOOD_GRADE_CHOICES } from "../../constance/dataTypes";
import { URL_BARCODE, URL_QC } from "../../constance/urls";
import * as _ from "lodash";
import { GET } from "../../utils/HttpClient";
import { ellipsisString } from "../../utils/stringFormat";
import useAntDropdownItem from "../../hooks/useAntDropdownItem";
import CustomDatePicker from "../common/antd/CustomDatePicker";


export default function NormalTypeDataForm (props) {
  const {
    form
  } = { ...defaultProps, ...props };

  const { t } = useTranslation();
  const produceDate = Form.useWatch('produce_date', form)
  const [loading, setLoading] = React.useState(false);
  const [sawNameOptions, setSawNameOptions] = React.useState([]);

  const sawTimeDropdown = useAntDropdownItem({ url: URL_BARCODE.SAW_TIME, key: 'code', value: 'code', label: 'time_name' })

  const fetchSawWoodDetail = async (date, saw_time) => {
    setLoading(true);
    setSawNameOptions([]);
    try {
      const response = await GET(URL_QC.SAW_WOOD_DETAIL, { date, saw_time })
      setSawNameOptions(_.get(response, 'data', []).map(data => ({
        key: _.get(data, 'code', ''),
        value: _.get(data, 'code', ''),
        label: _.get(data, 'code', ''),
        ...data
      })))
    } catch (error) {
      message.open({
        type: 'error',
        content: ellipsisString(error.errorMessages, 100),
      });
    } finally {
      setLoading(false);
    }
  }

  const resetDetail = () => {
    form.setFieldsValue({
      saw_name: null,
      head_disect: null,
      head_slice: null,
      tail_disect: null,
      tail_slice: null,
      head_disect_detail: null,
      head_slice_detail: null,
      tail_disect_detail: null,
      tail_slice_detail: null,
      employee: null,
      employee_detail: null,
      saw_table: null,
      grade: null,
    })
  }

  const handleChangeProduceDate = () => {
    form.setFieldValue('saw_time', null)
    resetDetail();
  }

  const handleChangeSawTime = (value) => {
    fetchSawWoodDetail(produceDate, value)
    resetDetail();
  }

  return (
    <div>
      <Form.Item name='produce_date' label={t('qc_raw_woods.produce_date')} rules={[{ required: true }]}>
        <CustomDatePicker style={{ width: '100%' }} onChange={handleChangeProduceDate}/>
      </Form.Item>
      <Form.Item name='saw_time' label={t('log_wood_qcs.saw_time')}>
        <Select options={sawTimeDropdown.options} placeholder={`${t('select')} ${t('log_wood_qcs.saw_time')}`} onChange={handleChangeSawTime}/>
      </Form.Item>
      <Form.Item name='saw_name' label={t('qc_raw_woods.saw_name')} rules={[{ required: true }]}>
        <Select
          options={sawNameOptions}
          loading={loading}
          onChange={(__, option) => {
            form.setFieldValue('head_disect', _.get(option, 'head_disect.code', null))
            form.setFieldValue('head_slice', _.get(option, 'head_slice.code', null))
            form.setFieldValue('tail_disect', _.get(option, 'tail_disect.code', null))
            form.setFieldValue('tail_slice', _.get(option, 'tail_slice.code', null))
            form.setFieldValue('head_disect_detail', _.get(option, 'head_disect.name', '-'))
            form.setFieldValue('head_slice_detail', _.get(option, 'head_slice.name', '-'))
            form.setFieldValue('tail_disect_detail', _.get(option, 'tail_disect.name', '-'))
            form.setFieldValue('tail_slice_detail', _.get(option, 'tail_slice.name', '-'))
            form.setFieldValue('employee', _.get(option, 'employee.code', null))
            form.setFieldValue('employee_detail', _.get(option, 'employee.name', '-'))
            form.setFieldValue('saw_table', _.get(option, 'saw_table', null))
          }}/>
      </Form.Item>

      <Form.Item name='head_disect' hidden/>
      <Form.Item name='head_disect_detail' label={t('qc_raw_woods.head_disect')}>
        <Input disabled/>
      </Form.Item>
      <Form.Item name='head_slice' hidden/>
      <Form.Item name='head_slice_detail' label={t('qc_raw_woods.head_slice')}>
        <Input disabled/>
      </Form.Item>
      <Form.Item name='tail_disect' hidden/>
      <Form.Item name='tail_disect_detail' label={t('qc_raw_woods.tail_disect')}>
        <Input disabled/>
      </Form.Item>
      <Form.Item name='tail_slice' hidden/>
      <Form.Item name='tail_slice_detail' label={t('qc_raw_woods.tail_slice')}>
        <Input disabled/>
      </Form.Item>
      <Form.Item name='saw_table' hidden/>

      <Form.Item name='employee' hidden/>
      <Form.Item name='employee_detail' label={t('qc_raw_woods.on_table_employee')}>
        <Input disabled/>
      </Form.Item>
      <Form.Item name='grade' label={t('wood_imports.grade')} rules={[{ required: true }]}>
        <Select
          options={WOOD_GRADE_CHOICES}
          labelRender='text'
          placeholder={`${t('select')} ${t('wood_imports.grade')}`}/>
      </Form.Item>
    </div>
  )
}

const defaultProps = {
  form: null
}

NormalTypeDataForm.propTypes = {
  form: propTypes.any
}