export const EMPLOYEE_ROLES = {
  ROLE_MANAGER: 'ROLE_MANAGER',
  ROLE_SUPER_USER: 'ROLE_SUPER_USER',
  ROLE_CLERK: 'ROLE_CLERK',
  ROLE_SEASON_WOOD: 'ROLE_SEASON_WOOD',
  ROLE_BAKE_WOOD: 'ROLE_BAKE_WOOD',
  ROLE_EXPENSE_WOOD: 'ROLE_EXPENSE_WOOD',
  ROLE_QC: 'ROLE_QC',
  ROLE_INFO: 'ROLE_INFO',
  ROLE_SUPER_CLERK: 'ROLE_SUPER_CLERK',
  ROLE_SUPER_SEASON_WOOD: 'ROLE_SUPER_SEASON_WOOD',
  ROLE_SUPER_BAKE_WOOD: 'ROLE_SUPER_BAKE_WOOD',
  ROLE_SUPER_EXPENSE_WOOD: 'ROLE_SUPER_EXPENSE_WOOD',
  ROLE_SUPER_QC: 'ROLE_SUPER_QC',
  ROLE_SUPER_INFO: 'ROLE_SUPER_INFO',
  DRY_WOOD_SORT: 'DRY_WOOD_SORT',
}