import { SaveOutlined } from "@ant-design/icons";
import { Button, Flex, Form, message } from "antd";
import propTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { GET, POST } from "../../utils/HttpClient";
import { URL_QC } from "../../constance/urls";
import ErrorMessage from "../common/antd/ErrorMessage";
import DetailForm from "./DetailForm";


export default function InputDetail (props) {
  const {
    data,
    onBack,
  } = { ...defaultProps, ...props };

  const { t } = useTranslation(); 
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_QC.QC_POST_BAKING_WOOD_TRANSACTION_RETRIEVE_BY_DETAIL, data)
      form.setFieldsValue({...response.data})
    } catch (error) {
      form.setFieldsValue({...data})
    } finally {
      setLoading(false);
    }
  }

  const onSubmit = async (values) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await POST(URL_QC.QC_POST_BAKING_WOOD_TRANSACTION, values)
      message.success({content: t('success')})
      onBack()
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (data) {
      fetchData()
    }
  }, [data])

  return (
    <div>
      <ErrorMessage message={errorMessages}/>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name='leave_oven_date' hidden rules={[{ required: true }]}/>
        <Form.Item name='oven' hidden rules={[{ required: true }]}/>

        <DetailForm form={form}/>

        <Flex justify='center' gap='middle'>
          <Button type='primary' icon={<SaveOutlined/>} loading={loading} htmlType='submit'>{t('save')}</Button>
          <Button onClick={onBack} loading={loading}>{t('back')}</Button>
        </Flex>
      </Form>
    </div>
  )
}

const defaultProps = {
  data: {
    date: null,
    oven: null,
  },
  onBack: () => null,
}

InputDetail.propTypes = {
  data: propTypes.shape({ 
    date: propTypes.string,
    oven: propTypes.string,
  }),
  onBack: propTypes.func,
}